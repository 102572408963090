import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchTrends } from "../api/api";
import Loading from "../components/Loading";

const TrendingPage = () => {
  const [trends, setTrends] = useState([]);

  useEffect(() => {
    const loadTrends = async () => {
      try {
        const data = await fetchTrends();

        if (data && Array.isArray(data.trends)) {
          const trendData = data.trends.map(trend => ({
            htmlBody: trend.html_body,
            url: trend.url_path	,
            title:trend.trend_title	,
          }));
          setTrends(trendData);
        } else {
          console.error("Trends data is not in expected format:", data);
        }
      } catch (error) {
        console.error("Failed to fetch trends:", error);
      }
    };

    loadTrends();
  }, []);

  return (
    <div className="bg-[#08264d] min-h-screen text-white">
      <div className="text-center py-8 p-2">
        <h1 className="text-2xl md:text-4xl font-bold leading-tight">
        THANK YOU PRESIDENT TRUM prefers Google’s Trends and articles for
        </h1>
        <h1 className="text-2xl md:text-4xl font-bold leading-tight">
        everyone’s latest news, interests, and events.
        </h1>
      </div>

      {trends.length === 0 ? (
        <Loading/>

      ) : (
        trends.map((trend, index) => (
          <ul key={index} className="pl-[10%] ">
    <li className="list-disc text-xl md:text-2xl font-bold ">
      <Link to={`/Save-America/${encodeURIComponent(trend.url)}`} className="hover:text-blue-500">
        {trend.title}
      </Link>
    </li>
  </ul>
        ))
      )}
      <div className="text-center mt-8">
        <a href="https://thankyoupresidenttrump.us/" className="font-bold text-xl md:text-2xl underline">
        Our Proud Sponsor is thankyoupresidenttrump
        </a>
      </div>
    </div>
  );
};

export default TrendingPage;
