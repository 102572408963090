import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { saveEmail, checkEmailExist } from "../api/api";

const EmailPopup = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(true); 

  useEffect(() => {
    const hasEnteredEmail = localStorage.getItem("hasEnteredEmail");

    // Show the alert if the email exists in local storage
    if (hasEnteredEmail === "true") {
      Swal.fire({
        title: "FELLOW TRUMPER",
        text: "You have already signed up for fantastic notifications and fabulous gifts. Stay tuned for more updates as you continue to be at the forefront of the MAGA revolution. Remember - President Trump will fix it",
        icon: "info",
        confirmButtonText: "Close",
      }).then(() => {
        setIsVisible(false); 
      });
    } else {
      setIsVisible(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const responseData = await checkEmailExist(email);

      if (responseData && responseData.message === "email does not exist") {
        const saved = await saveEmail(email);
        if (saved) {
          Swal.fire({
            title: "FELLOW TRUMPER",
            text: "Thanks for signing up for fantastic notifications and fabulous gifts. You are now at the forefront of the MAGA revolution and remember - President Trump will fix it.",
            icon: "info",
            confirmButtonText: "Close",
          }).then(() => {
            localStorage.setItem("hasEnteredEmail", "true");
            setIsVisible(false); 
          });
        } else {
          setError("Failed to save email. Please try again.");
        }
      } else if (responseData && responseData.message === "email exists") {
        Swal.fire({
          title: "FELLOW TRUMPER",
          text: "You have already signed up for fantastic notifications and fabulous gifts. Stay tuned for more updates as you continue to be at the forefront of the MAGA revolution. Remember - President Trump will fix it",
          icon: "info",
          confirmButtonText: "Close",
        }).then(() => {
          localStorage.setItem("hasEnteredEmail", "true");
          setIsVisible(false); 
        });
      } else {
        setError("Unexpected response. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (!isVisible) return null; 

  return (
    <div>
      <div className="mt-[37%] md:mt-[13%] fixed bg-[#0b2866] p-6 rounded shadow-md w-[80%] md:w-[60%] lg:w-[33%] left-1/2 transform -translate-x-1/2">
        <h2 className="text-xl text-white font-extrabold mb-4">
          Enter email to receive MAGA offers on the book, hardcover, paperback,
          digital download & audiobook.
        </h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border border-gray-300 p-3 mb-4 w-full rounded-xl placeholder: font-mono text-xl"
            placeholder="ENTER YOUR EMAIL"
            required
          />
          {error && <p className="text-red-500 mb-2">{error}</p>}
          <div className="flex justify-center">
            <button
              type="submit"
              className="text-[#0b2866] bg-white px-4 py-2 rounded-md font-bold text-xl"
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailPopup;
