import React from "react";
import { useState , useEffect  } from "react";
import ss1 from "../assets/imgs/accessPageImgs/pic1.png";
import ss2 from "../assets/imgs/accessPageImgs/p2.png";
import ss3 from "../assets/imgs/accessPageImgs/pic3.png";
import ss4 from "../assets/imgs/accessPageImgs/pic5.png";
import ss5 from "../assets/imgs/accessPageImgs/pic6.png";


import col2pic1 from "../assets/imgs/accessPageImgs/col2pic1.png";
import col2pic2 from "../assets/imgs/accessPageImgs/col2pic2.png";
import col2pic3 from "../assets/imgs/accessPageImgs/col2pic3.png";
import col2pic4 from "../assets/imgs/accessPageImgs/col2pic4.png";
import col2pic5 from "../assets/imgs/accessPageImgs/col2pic5.png";

import pss1 from "../assets/imgs/accessPageImgs/pss1.png";

import b3 from "../assets/imgs/pic3.png";

import share from "../assets/svgs/whiteshare.svg";
import buyme from "../assets/imgs/accessPageImgs/buyme.png";

import colss1 from "../assets/imgs/accessPageImgs/col-ss1.png";
import colss2 from "../assets/imgs/accessPageImgs/col-ss2.png";
import colss3 from "../assets/imgs/accessPageImgs/col-ss3.png";

import col2ss1 from "../assets/imgs/accessPageImgs/col2-ss1.png";
import col2ss2 from "../assets/imgs/accessPageImgs/col2-ss2.png";
import p21 from "../assets/imgs/accessPageImgs/p21.png";

import ShareModal from "../components/ShareModal";
import EmailPopup from "../components/EmailPopup";

function AccessPage() {
  const [textShadowStyle, setTextShadowStyle] = useState({});

  const images = [
    {src: buyme , alt:"pageImg2" ,link:"https://buymeacoffee.com/greghalpern"},

    {src: ss1 , alt:"pageImg1" ,link:"http://thankyoupresidenttrump.us"},
    {src: pss1 , alt:"pageImg2" ,link:"http://thankyoupresidenttrump.us"},

    
    {src: ss3 , alt:"pageImg3" },

  ];
  const fontstyle = {
    fontFamily: "Lovelo Line Bold",
    fontSize: '3.8vw',
  };
  
  const [isShareVisible, setIsShareVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const handleShareClick = () => {
    setIsShareVisible(true);
  };
  const handleEmailModalClose = () => {
    setIsEmailModalVisible(false);
  };

  const handleClose = () => {
    setIsShareVisible(false);
  };


  useEffect(() => {
    const updateStyle = () => {
      setTextShadowStyle(
        window.innerWidth >= 768
          ? { textShadow: "3px 2px #b4dc9b, 4px 0 #b4dc9b, 4px 0 #b4dc9b" }
          : {}
      );
    };


    // Set initial style based on window width
    updateStyle();

    // Update style on window resize
    window.addEventListener("resize", updateStyle);
    return () => window.removeEventListener("resize", updateStyle);
  }, []);
  return (
    <div className={` ${isEmailModalVisible ? 'backdrop-blur' : ''}`} style={{ backgroundColor: "#0b2866" }}>   
    <div className=" items-center justify-center">
        <EmailPopup/>

        </div>  
     <div className="grid grid-cols-1 ">
        {/* Images from p1 to p19*/}
        <div
  className="text-[#ebc645] text-center  font-bold md:font-semibold  pb-4 md:pb-8 pt-8 md:tracking-[0.2rem]"
  style={textShadowStyle}
>
  <h1 style={fontstyle}>
    GET IN ON SAYING THANK YOU <br className="md:my-4"></br> PRESIDENT TRUMP FOR SAVING AMERICA
  </h1>

</div>

        {images.map((image, index) => (
  <div
    key={index}
    className={` md:px-[10%] px-[2.5%] rounded overflow-hidden ${index === 1 ? 'mb-4 ' : ''} `}
  >
    {image.link ? (
      <a href={image.link} target="_blank" rel="noopener noreferrer">
        <img
          src={image.src}
          alt={image.alt}
          className="w-full h-auto object-cover"
        />
      </a>
    ) : (
      <img
        src={image.src}
        alt={image.alt}
        className="w-full h-auto object-cover"
      />
    )}
  </div>
))}

<iframe
                src="https://player.vimeo.com/video/1027371667"
                className="w-full h-[57vw] md:h-[47vw] object-cover pt-4"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>

  

  <div className=" px-[4%] ml-1 md:ml-0 md:px-[9%] rounded overflow-hidden w-full pt-4">
        <a href=  "https://whatthatmeans.nyc3.cdn.digitaloceanspaces.com/THANKYOUPRESIDENTTRUMP.pdf"target="_blank" rel="noopener noreferrer" className="">

    <img
      src={ss4}
      alt="pageImg 20"
      className="w-full h-auto object-cover"
    />
    </a>
</div> 
<div className=" px-[4%] ml-1 md:ml-0 md:px-[9%] rounded overflow-hidden w-full pb-2 pt-4">
    
    <img
      src={ss5}
      alt="pageImg 20"
      className="w-full h-auto object-cover"
    />
</div> 

<div
  className=" text-[#ebc645] text-center md:text-3xl text-lg lg:text-5xl underline font-bold md:font-semibold pb-8 pt-4 md:tracking-[0.4rem] uppercase"
  style={{fontstyle,
    textShadowStyle
  }}
>
<a href=  "https://whatthatmeans.net/accesspage"target="_blank" rel="noopener noreferrer" className="">

  <h1 style={{
    fontSize: '3.5vw',  
  }}>Valuable Free MAGA ACCESS INCLUDES</h1>
  <ol className=" mt-6 space-y-8" style={{
    fontSize: '3.5vw',  
  }}>
    <li>1)All GREAT Things MAGA</li>
    <li>2)EPIC LIFE IMPROVEMENT TOOLS</li>
    <li>3)Instant Happiness Meditations</li>
    <li>4)Breakthrough hacks for wealth</li>
  </ol>
  </a>
</div>
{/* ss20 */}
<div className="rounded px-[8%] overflow-hidden w-full ">
        <a href=  "https://books.google.com/books/about/Save_America.html?id=gtzO0AEACAAJ&source=kp_book_description/"target="_blank" rel="noopener noreferrer" className="">

      <img
        src={b3}
        alt="pageImg 20"
        className="w-full h-auto object-cover"
      />
      </a>
  </div>  

 

  <div className="text-[#ebc645] text-center text-[12px] md:text-[22px]  lg:text-[41px] underline font-bold  pb-8 pt-4 md:tracking-[0.1rem] uppercase"
  >
    <a href="https://www.amazon.com/Save-America-Donald-J-Trump/dp/B0D9HS4CWN" >
    <h1 style={{
    fontSize: '2.5vw',  
  }}>
    "SAVE AMERICA" President Donald J. Trump offers an<br></br>unparalleled look into his four years as the 45th
    <br></br>President of the United States, and a vision for his
    <br></br>next term! "SAVE AMERICA" is filled with iconic<br></br>moments from the first Trump Administration.
    </h1>
    </a>
  </div>


{/*  */}
<div className=" px-[4%] ml-1 md:ml-0 md:px-[9%] rounded overflow-hidden w-full">
    
    <img
      src={p21}
      alt="pageImg 20"
      className="w-full h-auto object-cover"
    />
</div> 

<div className="mb-[-4%] grid grid-cols-5 w-[92%] md:w-[82%] ml-[5%] md:ml-[9%] mr-[4%]"> 
  {[
    { src: col2ss1, link: "https://inventorofcrowdfunding.com/", alt: "pageImg 7" },
    { src: col2ss2, link: "https://channelgreg.com", alt: "pageImg 8" },
    { src: colss1, link: "https://greghalpernlives.com", alt: "pageImg 4" },
    { src: colss2, link: "https://battletestedwinner.com", alt: "pageImg 5" },
    { src: colss3, link: "https://realalientechnology.com", alt: "pageImg 6" },
  ].map((item, index) => (
    <div
      key={index}
      className="" 
    >
      <a href={item.link} target="_blank" rel="noopener noreferrer" className="">
        <img
          src={item.src}
          alt={item.alt}
          className="w-full h-[80%] " 
        />
      </a>
    </div>
  ))}
</div>

<div className="pb-[-4%] grid grid-cols-5 w-[92%] md:w-[82%] ml-[5%] md:ml-[9%] mr-[4%]"> 
  {[
    { src: col2pic1, link: "https://whatthatmeans.com", alt: "pageImg 7" },
    { src: col2pic2, link: "https://thepeoplesvote.us", alt: "pageImg 8" },
    { src: col2pic3, link: "https://greghalpernvideo.com", alt: "pageImg 4" },
    { src: col2pic4, link: "https://whatthatmeans.net/access", alt: "pageImg 5" },
    { src: col2pic5, link: "https://formula4protocol.teachable.com", alt: "pageImg 6" },
  ].map((item, index) => (
    <div
      key={index}
      className="" 
    >
      <a href={item.link} target="_blank" rel="noopener noreferrer" className="">
        <img
          src={item.src}
          alt={item.alt}
          className="w-full h-[80%] " 
        />
      </a>
    </div>
  ))}
 
</div>
<div className="text-center mt-0">
        <a href="https://thankyoupresidenttrump.us/save-america" className="text-[#ebc645] font-bold text-2xl underline">
         Thank You President Trump supports Google’s Trends and articles for the latest news, interests, and events around the world.
        </a>
      </div>
 {/*  */}
        <div className="fixed mt-[6%] md:mt-[6%] ml-[77%] md:ml-[80%] ">
          <img
            src={share}
            alt="share icon"
            className=" cursor-pointer w-[70%] md:w-[95%]"
            onClick={handleShareClick}
          />
        </div>
      </div>
      {isShareVisible && <ShareModal onClose={handleClose} />}
    </div>
  );
}

export default AccessPage;
