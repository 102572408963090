import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSingelTrend } from "../api/api";
import Loading from "../components/Loading";

const TrendDetailPage = () => {
  const { url } = useParams();
  const [trend, setTrend] = useState(null);

  useEffect(() => {
    const loadTrendDetail = async () => {
      try {
        const data = await fetchSingelTrend(url);
        
        if (data && data.trends && data.trends.html_body) {
          let cleanHtml = data.trends.html_body
            .replace(/setCSMReq\([^)]*\);?/g, '') 
            .replace(/markFeatureRenderForImageBlock\([^)]*\);?/g, ''); 
          
          setTrend(cleanHtml);

        
          
        } else {
          console.error("Trend data not found");
        }
      } catch (error) {
        console.error("Failed to fetch trend details:", error);
      }
    };

    loadTrendDetail();
  }, [url]);

  return (
    <div>
      {trend ? (
        <div dangerouslySetInnerHTML={{ __html: trend }} />
      ) : (
        <div className="bg-[#08264d] min-h-screen text-white">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default TrendDetailPage;
