import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { fetchTrends } from "../api/api";

const TrendingTags = () => {
  const [trends, setTrends] = useState([]);

  useEffect(() => {
    const loadTrends = async () => {
      const data = await fetchTrends();
      if (data && data.trends) {
        const trendQueries = data.trends.map((trend) => trend.title);
        setTrends(trendQueries);
      }
    };

    loadTrends();
  }, []);

  return (
    <>
      <Helmet>
        <title>
        MAGA President Trump Saves America{/* {trends.length > 0
            } */}
        </title>
        <meta
          name="description"
          content="Get in on saying THANK YOU PRESIDENT TRUMP FOR SAVING AMERICA. Trump will fix it. Go MAGA."
          // content={`Learn about ${trends.join(", ")} and more trending topics.`}
        />
        <meta name="keywords" content={trends.join(", ")} />
      </Helmet>
    </>
  );
};

export default TrendingTags;
