import React, { useState } from "react";
import facebook from "../assets/svgs/facebook.svg";
import whatsapp from "../assets/svgs/whatsapp.svg";
import telegram from "../assets/svgs/telegram.svg";
import copyIcon from "../assets/svgs/copyIcon.svg";
import twitter from "../assets/svgs/twitter.svg";
import close from "../assets/svgs/Close.svg";
import instagram from "../assets/svgs/ig.svg";
import pinterest from "../assets/svgs/pinterest.svg";
import messenger from "../assets/svgs/messenger.svg";
import printIcon from "../assets/svgs/print.svg";

function ShareModal({ onClose }) {
  const [isPrinting, setIsPrinting] = useState(false);
  const baseUrl = "https://thankyoupresidenttrump.us/";
  const message = encodeURIComponent(
    `Get in on saying THANK YOU PRESIDENT TRUMP FOR SAVING AMERICA. Trump will fix it. Go MAGA.  Visit us at ${baseUrl}`
  );

  const handlePrint = () => {
    setIsPrinting(true); 
    setTimeout(() => {
      window.print();
      setIsPrinting(false); 
    }, 0);
  };

  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${isPrinting ? 'hidden' : ''}`}>
      <div className="bg-white lg:w-[30%] md:w-[60%] w-[90%] p-6 rounded-lg relative">
        <button className="absolute top-2 right-2 text-gray-500" onClick={onClose}>
          <img src={close} alt="Close" />
        </button>
        <h1 className="text-2xl text-[#212060] font-extrabold mb-4">SHARE</h1>
        <hr className="border border-[#2120609C] w-[8]" />
        <div className="flex mt-[5%] justify-around mb-4">
          {/* Social media icons */}
          <img src={facebook} alt="Facebook" className="w-10 h-10 cursor-pointer" onClick={() => { window.open(`https://www.facebook.com/sharer/sharer.php?u=${baseUrl}`, "_blank"); }} />
          <img src={messenger} alt="Messenger" className="w-10 h-10 cursor-pointer" onClick={() => { window.open(`https://www.facebook.com/dialog/send?link=${baseUrl}&app_id=YOUR_APP_ID`, "_blank"); }} />
          <img src={telegram} alt="Telegram" className="w-10 h-10 cursor-pointer" onClick={() => { window.open(`https://t.me/share/url?url=${message}`, "_blank"); }} />
          <img src={whatsapp} alt="WhatsApp" className="w-10 h-10 cursor-pointer" onClick={() => { window.open(`https://wa.me/?text=${message}`, "_blank"); }} />
          <img src={twitter} alt="Twitter" className="w-10 h-10 cursor-pointer" onClick={() => { window.open(`https://twitter.com/intent/tweet?text=${message}`, "_blank"); }} />
          <img src={instagram} alt="Instagram" className="w-10 h-10 cursor-pointer" onClick={() => { window.open(`https://www.instagram.com`, "_blank"); }} />
          <img src={pinterest} alt="Pinterest" className="w-10 h-10 cursor-pointer" onClick={() => { window.open(`https://www.pinterest.com/pin/create/button/?url=${baseUrl}&media=${baseUrl}/logo192.png&description=Check out this amazing content!`, "_blank"); }} />
          {/* Print */}
          <img src={printIcon} alt="Print" className="w-10 h-10 cursor-pointer" onClick={handlePrint} />
        </div>

        <div className="flex items-center justify-between bg-gray-100 p-2 rounded-lg">
          <span className="text-gray-700">Copy Link</span>
          <img src={copyIcon} alt="Copy Link" className="w-6 h-6 cursor-pointer" onClick={() => { navigator.clipboard.writeText(baseUrl); alert("Link copied to clipboard!"); }} />
        </div>
      </div>
    </div>
  );
}

export default ShareModal;
