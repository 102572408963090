import axios from 'axios';


export const fetchTrends = async () => {
  try {
    
    const response = await fetch('https://api.thepeoplesvote.us/donald-trends-articles', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Google trends:', error);
    return null;
  }
};
export const fetchSingelTrend = async (url) => {
  try {
    const response = await axios.get(`https://api.thepeoplesvote.us/v1/google-trend?url=${url}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch data: ' + error.message);
  }
};
  
// 
export const checkEmailExist = async (email) => {
  try {
    const response = await axios.post(`https://api.thepeoplesvote.us/check-vote-exist`, { email });
    return response.data; 
  } catch (error) {
    console.error("Error checking email existence:", error);
    return null; 
  }
};

  // Function to save the email
 export const saveEmail = async (email) => {
    try {
      const response = await axios.post(`https://api.thepeoplesvote.us/save-email`, { email, source: "thankyoupresidenttrump.us" });
      return response.status === 200; 
    } catch (error) {
      console.error("Error saving email:", error);
      return false; 
    }
  };